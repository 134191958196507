import React, { useState } from "react"
import styled from "styled-components"
import WhatOthersSay from "./images/what-others-say"
import avatar0 from "../images/suzanne-pols.jpg"
import avatar1 from "../images/suzanne-agterberg.jpg"
import avatar2 from "../images/ontdek-autisme.png"
import avatar3 from "../images/linda-de-wit.jpg"
import avatar4 from "../images/adelien-bergman.jpg"
import avatar5 from "../images/jeroen-bartels.jpg"

const Section = styled.div`
  color: #4a8591;
  background-image: radial-gradient(
    circle at bottom center,
    #ffffff 0%,
    #d2f7fe 100%
  );
`

const reviews = [
  {
    id: 6,
    avatar: { src: avatar5, alt: "Jeroen Bartels" },
    text: "Ik heb ‘een doosje vol talent’ gebruikt in mijn werk als GZ-psycholoog met jongeren met autisme. Het is als hulpverlener fijn om iets in handen te hebben (bijvoorbeeld tijdens psycho-educatie) waardoor je makkelijk in gesprek komt. Het materiaal is laagdrempelig, geeft structuur en legt de nadruk op krachten en kwaliteiten van het kind/de jongere. Een mooi hulpmiddel in de hulpverlening voor kinderen en jongeren met autisme!",
    author: "Jeroen Bartels",
    tagline: null,
  },
  {
    id: 3,
    avatar: { src: avatar1, alt: "Suzanne Agterberg" },
    text: "Ik hecht als autismespecialist veel waarde aan goede en realistische psycho-educatie over autisme. Maar in het benadrukken van de belemmeringen en de stress die het hebben van autisme met zich meebrengt vergeten we wel eens deze kinderen te blijven wijzen op wat wél goed gaat en wat zij misschien juist door hun autisme wel extra goed kunnen. Dit spel onderscheidt zich van bijvoorbeeld een ‘Kinderkwaliteitenspel’ omdat het juist de (mogelijk) sterke kanten van autisme in beeld brengt.  Met het doosje vol talent heb je een schat in handen waarmee je kinderen met autisme op een laagdrempelige manier (zonder uitgebreide handleidingen) kunt laten zien wat ze allemaal wél kunnen, en dat (hun) autisme ook heel veel goede en sterke kanten kan hebben. Eén van mijn cliënten zei letterlijk: “Wel fijn om je te herinneren dat dit ook allemaal bij autisme hoort!” en “Ik zou willen dat mijn mentor dit zag, want dan zou hij mij veel beter begrijpen”. Ik raad iedereen die werkt of leert met kinderen met autisme dit hulpmiddel aan en hoop dat het veel kinderen weer laat herinneren dat ze ook enorm veel dingen (heel) goed kunnen!",
    author: "Suzanne Agterberg",
    tagline: (
      <>
        <a
          href="https://www.spectrumvisie.nl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Autismespecialist
        </a>
        , leerkracht, trainer autisme en auteur van{" "}
        <a
          href="https://www.uitgeverijpica.nl/titels/onderwijs/begeleiding-ondersteuning/vind-je-eigen-weg-met-jouw-autisme-pica"
          target="_blank"
          rel="noopener noreferrer"
        >
          “Vind je eigen weg met jouw autisme”
        </a>
      </>
    ),
  },
  {
    id: 1,
    avatar: { src: avatar4, alt: "Adelien Bergman" },
    text: "Ik heb met een 17 jarige jongen (Asperger) met het doosje gewerkt. Hij heeft de kaarten goed kunnen gebruiken om een swot-analyse te maken. De stellingen hielpen bij het inzicht krijgen op zijn talenten, maar ook de keerzijde (de valkuilen) kwamen langs. Zo is hij detailgericht, maar verliest soms het overzicht. Het doosje is dus een waardevolle aanvulling in gesprekken. De kaarten zien er ook mooi uit. Ik ben er blij mee en ga ze zeker vaker gebruiken!",
    author: "Adelien Bergman",
    tagline: "Remedial teacher",
  },
  {
    id: 2,
    avatar: { src: avatar0, alt: "Suzanne Pols" },
    text: "De kaartjes zijn mooi en overzichtelijk vormgegeven waardoor ze voor kinderen (en ouders) met ASS duidelijk zijn, maar ook voor de gezinsleden zonder ASS er uitdagend genoeg uitzien. Ik ken ook andere spellen met hetzelfde idee, maar uniek aan dit spel is dat het gedrag waarvan ouders regelmatig aangeven last te hebben hier als talenten samenkomen. Hierdoor kan de negatieve lading omtrent dit gedrag een positieve wending krijgen, waardoor er een opening is om gedrag op een andere manier te bekijken. Ik kan niet wachten om meer gezinnen in deze manier van denken mee te denken met als resultaat dat kinderen met ASS zich gezien voelen op een positieve manier!",
    author: "Suzanne Pols",
    tagline: "Gedragswetenschapper ambulante crisishulpverlening",
  },
  {
    id: 4,
    avatar: { src: avatar2, alt: "Ontdek Autisme" },
    text: "Ieder mens is uniek, deze kaartenset richt zich op de talenten die mensen kunnen hebben. Het opent gesprekken en nieuwe mogelijkheden. Houd in de gaten dat iedereen op een eigen manier zal kijken naar de begrippen, wat ons betreft is het proces belangrijker dan de uitkomst. We wensen je veel inzichten!",
    author: (
      <a
        href="https://ontdekautisme.nl/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Ontdek Autisme
      </a>
    ),
    tagline: null,
  },
  {
    id: 5,
    avatar: { src: avatar3, alt: "Linda de Wit" },
    text: "Vandaag hebben wij het Doosje van Talent uitgeprobeerd en dit was heel leuk! We hebben de uitgebreide variant gekozen waarbij we ieder kaartje van de stapel hebben besproken, maar ook hebben gekeken hoe en waarbij je dit talent kunt gebruiken. Voor de kinderen is dit heel leerzaam en leuk omdat je op een positieve manier bespreekt wat hun talenten zijn en wat ze heel goed kunnen. Je ziet het zelfvertrouwen groeien, en voor mij was het ook heel leuk om te zien hoe ze tegen mij als ouder aan kijken.  Naast de talenten kun je eventueel ook nog de kaartjes bespreken die zijn blijven liggen: wat vind je moeilijk en hoe kun je hiermee omgaan? Wij gaan het zeker vaker doen, want het is een leuke en creatieve manier van kijken naar iemand, maar ook om te kijken of het een paar dagen later nog hetzelfde is, of dat ze anders tegen een situatie aankijken.",
    author: "Linda de Wit",
    tagline: (
      <>
        Auteur van het boek{" "}
        <a
          href="https://www.facebook.com/mamahebikdatook/"
          target="_blank"
          rel="noopener noreferrer"
        >
          “Mama, heb ik dat ook?”
        </a>{" "}
        en moeder van een dochter met MCDD.
      </>
    ),
  },
]

const MAX_REVIEWS = 2

export default function WhatOthersSayWrapper() {
  const [expand, setExpand] = useState(false)
  const visibleReviews = expand ? reviews : reviews.slice(0, MAX_REVIEWS)
  return (
    <Section
      name="wat-anderen-zeggen"
      className="d-flex align-items-center justify-content-center pb-0 pb-lg-5"
    >
      <div className="container container-fluid">
        <div className="row">
          <div className="mx-auto col-lg-10 text-md-center px-0 px-md-2 py-3 py-sm-5">
            <div
              className="mx-auto my-3"
              style={{ maxWidth: 365, maxHeight: 31 }}
            >
              <WhatOthersSay />
            </div>

            {visibleReviews.map((review) => (
              <div
                key={review.id}
                className="mx-0 my-5 mx-md-4 px-4 py-4 px-md-5 rounded-lg shadow-lg bg-white text-dark text-center"
              >
                <img
                  src={review.avatar.src}
                  alt={review.avatar.alt}
                  className="mb-3 rounded-circle"
                  style={{
                    width: 96,
                    height: 96,
                    objectFit: "cover",
                  }}
                />

                <blockquote>“{review.text}”</blockquote>

                <div className="mt-4 text-center text-uppercase font-weight-bold text-dark">
                  {review.author}
                </div>
                {review.tagline ? (
                  <p className="mx-auto col-lg-7 small text-muted mt-1">
                    {review.tagline}
                  </p>
                ) : null}
              </div>
            ))}
          </div>
        </div>

        {!expand ? (
          <div className="row">
            <div className="mt-0 mb-5 pb-3 w-100 text-center">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  setExpand(true)
                }}
              >
                Lees nog {reviews.length - visibleReviews.length} meer
                reviews...
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </Section>
  )
}
