import React from "react"
import IconOne from "./images/icon-one"
import IconTwo from "./images/icon-two"
import IconThree from "./images/icon-three"

export default function ListItem({ index, children }) {
  const icon =
    index === 1 ? (
      <IconOne />
    ) : index === 2 ? (
      <IconTwo />
    ) : index === 3 ? (
      <IconThree />
    ) : (
      "?"
    )
  return (
    <div className="d-flex mb-2">
      <div className="mr-2" style={{ width: 20, height: 20, marginTop: 2 }}>
        {icon}
      </div>
      <div style={{ flex: 1 }}>{children}</div>
    </div>
  )
}
