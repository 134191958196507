import React from "react"
import WhatsAppIcon from "./images/whatsapp-icon"
import styled from "styled-components"

const FloatingButton = styled.a`
  background: #20b038;
  box-shadow: 0 4px 12px 0 rgba(136, 136, 136, 0.5);
  border-radius: 6px;
  color: #efefef;
  padding: 10px 20px;
  font-size: 90%;
  z-index: 500;

  &:hover {
    color: white;
    text-decoration: none;
  }

  display: flex;

  // On non-mobile screens
  //@media (min-width: 576px) {
  position: fixed;
  top: 20px;
  //bottom: 3rem;
  right: 20px;
  transition: transform 0.25s ease-in-out;
  transform: translate(216px, 0);
  //}

  &:hover {
    transform: translate(0, 0);
  }

  // Small devices (landscape phones, 576px and up)

  // Medium devices (tablets, 768px and up)
  // @media (min-width: 768px) { ... }

  // Large devices (desktops, 992px and up)
  // @media (min-width: 992px) { ... }
`

export default function WhatsAppButton({ index, children }) {
  return (
    <FloatingButton
      href="https://wa.me/31624266571?text=Ik+heb+een+vraagje+over+het+Doosje+Vol+Talent."
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="mr-3" style={{ width: 51, height: 54 }}>
        <WhatsAppIcon />
      </div>
      <div className="d-flex flex-column align-items-start justify-content-center">
        <div className="font-weight-bold m-0">Vraagje?</div>
        <div>Stuur mij een WhatsAppje!</div>
      </div>
    </FloatingButton>
  )
}
