import React from "react"

import Layout from "../components/layout"
import WhatsAppButton from "../components/WhatsAppButton"
import OrderSection from "../components/OrderSection"
import HowItWorksSection from "../components/HowItWorksSection"
import WhatOthersSaySection from "../components/WhatOthersSaySection"
import OtherProductsSection from "../components/OtherProductsSection"
import HeroSection from "../components/HeroSection"
import Seo from "../components/seo"

export default function IndexPage() {
  return (
    <Layout>
      <Seo title="Doosje vol talent" />

      <HeroSection />
      <WhatsAppButton />
      <HowItWorksSection />
      <OtherProductsSection />
      <WhatOthersSaySection />
      <OrderSection />
    </Layout>
  )
}
