import React from "react"
import styled from "styled-components"
import Cardbox from "../components/images/cardbox"
import Logo from "./images/logo"
import { Link as ScrollLink } from "react-scroll"

const Section = styled.div`
  color: #3c7683;
  background-image: radial-gradient(50% 150%, #fdfacb 50%, #e4e1ac 100%);
`

export default function HeroSection() {
  return (
    <Section>
      <div className="container container-fluid">
        <div className="row">
          <div className="col-sm-5">
            <div
              className="mx-auto mr-sm-0 ml-sm-auto my-3 my-sm-5"
              style={{ maxWidth: 800 / 2, maxHeight: 800 / 2 }}
            >
              <Cardbox />
            </div>
          </div>

          <div className="col-sm-7">
            <div className="ml-sm-4">
              <div className="my-3 my-sm-5">
                <div className="mt-3" style={{ maxWidth: 347, maxHeight: 50 }}>
                  <Logo />
                </div>

                <p className="py-1 h5">
                  Leer jezelf beter kennen en jouw talenten gebruiken!
                </p>
                <p>
                  Een talentenspel speciaal ontwikkeld voor en door kinderen met
                  autisme.
                </p>

                <div className="py-3 d-flex flex-column flex-md-row justify-sm-content-center align-items-md-start">
                  <ScrollLink
                    href="#"
                    to="hoe-werkt-het"
                    smooth={true}
                    duration={500}
                    className="btn btn-secondary py-2 px-4 font-weight-bold text-uppercase m-2 mt-0"
                    style={{
                      background: "rgb(236, 231, 164)",
                      border: "1px solid #86856f",
                      color: "#86856f",
                    }}
                  >
                    Hoe werkt het?
                  </ScrollLink>

                  <ScrollLink
                    href="#"
                    to="bestel-nu"
                    smooth={true}
                    duration={500}
                    className="btn btn-primary py-2 px-4 m-2 mt-0 font-weight-bold text-uppercase"
                    style={{
                      border: 0,
                    }}
                  >
                    Bestel nu
                  </ScrollLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
