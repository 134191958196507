import React, { useState } from "react"
import styled from "styled-components"
import ListItem from "./ListItem"
import Bestelformulier from "./images/bestelformulier"

const PRICE = 30

const Section = styled.div`
  color: white;
  background-image: radial-gradient(50% 150%, #6c6d68 50%, #2b3132 99%);
`

const currency = new Intl.NumberFormat("nl-NL", {
  style: "currency",
  currency: "EUR",
})

const DOOSJE_PRICE = 30
// const WERKBLAD_PRICE = 19

function asPosInt(e) {
  let num
  try {
    num = parseInt(e.currentTarget.value, 10)
  } catch {
    return 0
  }

  return Number.isFinite(num) ? Math.max(0, num) : 0
}

export default function OrderSection() {
  const [numDoosjes, setNumDoosjes] = useState(1)
  // const [numWerkbladen, setNumWerkbladen] = useState(0)
  const [numPosters, setNumPosters] = useState(0)

  // 1 poster = € 12 per stuk
  // 5 posters = € 11 per stuk
  // 10 posters = € 10 per stuk

  const POSTER_PER_10_PRICE = 10
  const POSTER_PER_5_PRICE = 11
  const POSTER_PER_1_PRICE = 12

  const numPosters10 = Math.floor(numPosters / 10)
  const numPosters5 = Math.floor((numPosters - numPosters10 * 10) / 5)
  const numPosters1 = numPosters - numPosters10 * 10 - numPosters5 * 5

  const posterPrice =
    numPosters10 * 10 * POSTER_PER_10_PRICE +
    numPosters5 * 5 * POSTER_PER_5_PRICE +
    numPosters1 * POSTER_PER_1_PRICE

  const SHIPPING_PRICE = 6.75
  const shippingPrice = numPosters > 0 ? SHIPPING_PRICE : 0
  const totalPrice =
    numDoosjes * DOOSJE_PRICE +
    // numWerkbladen * WERKBLAD_PRICE +
    posterPrice +
    shippingPrice

  return (
    <Section name="bestel-nu" className="d-flex justify-content-center">
      <div className="container container-fluid">
        <div className="row">
          <div className="mx-auto col-lg-8 mt-0 mt-sm-5 mt-lg-n5 py-3 mb-0 mb-sm-5 text-dark bg-white rounded shadow">
            {/* ---------------------------------------------------- */}

            <div className="text-center p-2 pb-4 my-3">
              <div className="d-none font-weight-bold display-3">
                <span style={{ color: "#c4d1d4" }}>&euro;</span>
                <span style={{ color: "#4a8591" }}>{PRICE}</span>
              </div>
              <div
                className="mx-auto my-1"
                style={{ maxWidth: 570 / 2, maxHeight: 82 / 2 }}
              >
                <Bestelformulier />
              </div>
              <div style={{ color: "#82b6c1" }}>Alle prijzen inclusief BTW</div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <h4>Bestelproces</h4>
                  <p>Je eigen doosje vol talent bestellen is heel eenvoudig!</p>

                  <ListItem index={1}>
                    Vul je gegevens in het formulier{" "}
                    <span className="d-inline d-lg-none">hieronder</span>
                    <span className="d-lg-inline d-none">hiernaast</span>
                  </ListItem>
                  <ListItem index={2}>
                    We sturen je dan een betaalverzoek via WhatsApp of per mail.
                    <div>
                      <strong>Let op!</strong> Dit betaalverzoek komt van{" "}
                      <strong>Aukje Reurink</strong>.
                    </div>
                  </ListItem>
                  <ListItem index={3}>
                    Als de betaling binnen is, wordt de bestelling verzonden.
                  </ListItem>
                </div>

                {/* |||||||||||||||||||||||||||||||||||||||||||||||||||||| */}

                <div className="col-lg-7">
                  <form
                    name="bestelformulier"
                    method="POST"
                    action="/thanks/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    {/*
                    NOTE: Required for Gatsby!
                    See https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#form-handling-with-static-site-generators
                  */}
                    <input
                      type="hidden"
                      name="form-name"
                      value="bestelformulier"
                    />
                    <div className="form-group">
                      <label htmlFor="field-fullname">Naam</label>
                      <input
                        className="form-control"
                        id="field-fullname"
                        name="naam"
                        required
                        placeholder="Voor- en achternaam"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="field-org">
                        Organisatie
                        <div className="small text-secondary">
                          (Indien van toepassing)
                        </div>
                      </label>
                      <input
                        className="form-control"
                        id="field-org"
                        name="organisatie"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="field-address">Adres</label>
                      <input
                        className="form-control"
                        id="field-address"
                        name="adres"
                        placeholder="Straat en huisnummer"
                        required
                      />
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="field-postalcode">Postcode</label>
                        <input
                          className="form-control"
                          id="field-postalcode"
                          name="postcode"
                          pattern="[1-9][0-9]{3}\s*[a-zA-Z]{2}"
                          required
                        />
                      </div>
                      <div className="form-group col-md-8">
                        <label htmlFor="field-city">Woonplaats</label>
                        <input
                          className="form-control"
                          id="field-city"
                          name="woonplaats"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="field-email">Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="field-email"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="field-tel">
                        Mobiele telefoonnummer
                        <div className="small text-secondary">
                          (Alleen nodig als je betaalvoorkeur WhatsApp is)
                        </div>
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        name="telefoon"
                        id="field-tel"
                      />
                    </div>

                    <div>
                      <div className="mb-2">Bestelling</div>

                      <div className="form-row">
                        <div className="form-group col-3">
                          <input
                            type="number"
                            className="form-control"
                            name="aantal-doosjes"
                            id="field-quantity"
                            defaultValue="1"
                            min={0}
                            value={numDoosjes}
                            onChange={(e) => {
                              setNumDoosjes(asPosInt(e))
                            }}
                          />
                        </div>
                        <div className="form-group col-6 my-1">
                          <label className="m-0" htmlFor="field-quantity">
                            Doosje vol Talent
                          </label>
                        </div>
                        <div className="form-group col-3 my-1 text-right">
                          {currency.format(numDoosjes * DOOSJE_PRICE)}
                        </div>
                      </div>

                      {/*
                      <div className="form-row">
                        <div className="form-group col-3">
                          <input
                            type="number"
                            className="form-control"
                            name="aantal-werkbladen"
                            id="field-quantity-werkblad"
                            defaultValue="0"
                            min={0}
                            value={numWerkbladen}
                            onChange={(e) => {
                              setNumWerkbladen(asPosInt(e))
                            }}
                          />
                        </div>
                        <div className="form-group col-6 my-1">
                          <label
                            className="m-0"
                            htmlFor="field-quantity-werkblad"
                          >
                            <div>Werkbladen</div>
                            <div className="small">(per 25 stuks)</div>
                          </label>
                        </div>
                        <div className="form-group col-3 my-1 text-right">
                          {currency.format(numWerkbladen * WERKBLAD_PRICE)}
                        </div>
                      </div>
                      */}

                      <div className="form-row">
                        <div className="form-group col-3">
                          <input
                            type="number"
                            className="form-control"
                            name="aantal-posters"
                            id="field-quantity-posters"
                            defaultValue="0"
                            min={0}
                            value={numPosters}
                            onChange={(e) => {
                              setNumPosters(asPosInt(e))
                            }}
                          />
                        </div>
                        <div className="form-group col-6 my-1">
                          <label
                            className="m-0"
                            htmlFor="field-quantity-posters"
                          >
                            <div>Posters</div>
                          </label>
                        </div>
                        <div className="form-group col-3 my-1 text-right">
                          {currency.format(posterPrice)}
                        </div>
                      </div>

                      {numPosters > 0 ? (
                        <div className="form-row">
                          <div className="form-group col-3"></div>
                          <div className="form-group col-6">
                            <div>Verzendkosten</div>
                          </div>
                          <div className="form-group col-3 text-right">
                            {currency.format(shippingPrice)}
                          </div>
                        </div>
                      ) : null}

                      <div className="form-row mb-3">
                        <div className="form-group col-9 my-1 pt-1">Totaal</div>
                        <div className="form-group col-3 my-1 pt-1 text-right font-weight-bold border-top">
                          {currency.format(totalPrice)}
                        </div>
                      </div>
                    </div>

                    {numDoosjes > 0 ? (
                      <>
                        {/*
                          <div className="mt-0 mb-3 small text-info rotate-3">
                            Cadeautje! Je ontvangt{" "}
                            <strong>5 werkbladen gratis</strong> bij je bestelling!
                            🤗
                          </div>
                        */}
                      </>
                    ) : null}

                    <div className="form-group">
                      <label htmlFor="field-notes">Opmerkingen</label>
                      <textarea
                        className="form-control"
                        id="field-notes"
                        name="opmerkingen"
                      ></textarea>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-5">
                        <div>Betaalvoorkeur</div>
                      </div>
                      <div className="form-group col-md-7">
                        <div className="form-check">
                          <input
                            id="whatsapp-plz"
                            className="form-check-input"
                            type="radio"
                            name="betaalvoorkeur"
                            value="Via WhatsApp"
                            defaultChecked
                          />
                          <label
                            htmlFor="whatsapp-plz"
                            className="form-check-label"
                          >
                            Via WhatsApp
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            id="email-plz"
                            className="form-check-input"
                            type="radio"
                            name="betaalvoorkeur"
                            value="Per email"
                          />
                          <label
                            htmlFor="email-plz"
                            className="form-check-label"
                          >
                            Per email
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="text-center text-lg-right p-2 p-md-3">
                      <button
                        type="submit"
                        className="px-4 btn btn-primary font-weight-bold text-uppercase"
                      >
                        Plaats bestelling
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* ---------------------------------------------------- */}
          </div>
        </div>
      </div>
    </Section>
  )
}
