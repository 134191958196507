import React, { useState } from "react"
import { useSprings, animated, interpolate } from "react-spring"
import { useDrag } from "react-use-gesture"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import How from "./images/how"
import "./deck-styles.css"
import card0 from "../images/card0.png"
import card1 from "../images/card1.png"
import card2 from "../images/card2.png"
import card3 from "../images/card3.png"
import card4 from "../images/card4.png"

const Section = styled.div`
  color: #4a8591;
  background-image: linear-gradient(180deg, #edfffb 0%, #d2f7ff 100%);
`

const cards = [card0, card1, card2, card3, card4]

// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
})
const from = (i) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 })
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`

function Deck() {
  const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
  const [props, set] = useSprings(cards.length, (i) => ({
    ...to(i),
    from: from(i),
  })) // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useDrag(
    ({
      args: [index],
      down,
      movement: [xDelta],
      distance,
      direction: [xDir],
      velocity,
    }) => {
      const trigger = velocity > 0.2 // If you flick hard enough it should trigger the card to fly out
      const dir = xDir < 0 ? -1 : 1 // Direction should either point left or right
      if (!down && trigger) gone.add(index) // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      set((i) => {
        if (index !== i) return // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index)
        const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
        const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0) // How much the card tilts, flicking it harder makes it rotate faster
        const scale = down ? 1.1 : 1 // Active cards lift up a bit
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        }
      })
      if (!down && gone.size === cards.length)
        setTimeout(() => gone.clear() || set((i) => to(i)), 600)
    },
  )
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return props.map(({ x, y, rot, scale }, i) => (
    <animated.div
      key={i}
      className="animation-area"
      style={{
        transform: interpolate(
          [x, y],
          (x, y) => `translate3d(${x}px,${y}px,0)`,
        ),
      }}
    >
      {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
      <animated.div
        className="animated-card"
        {...bind(i)}
        style={{
          transform: interpolate([rot, scale], trans),
          backgroundImage: `url(${cards[i]})`,
        }}
      />
    </animated.div>
  ))
}

export default function HowItWorksSection() {
  const [ref, isDeckInView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  })
  return (
    <Section
      name="hoe-werkt-het"
      className="d-flex align-items-center justify-content-center pb-0 pb-lg-5"
    >
      {/* Preload images for card deck animation */}
      <div style={{ display: "none" }}>
        <img src={card0} alt="" />
        <img src={card1} alt="" />
        <img src={card2} alt="" />
        <img src={card3} alt="" />
        <img src={card4} alt="" />
      </div>

      <div className="container container-fluid">
        <div className="row">
          <div className="mx-auto col-lg-7 text-md-center py-3 py-sm-5">
            <div
              className="mx-auto my-3"
              style={{ maxWidth: 265, maxHeight: 31 }}
            >
              <How />
            </div>

            <p>
              Het "Doosje vol talent" geeft je op een leuke manier meer inzicht
              in jouw talenten. Het spel is opgedeeld in 10 categorieën met
              bijbehorende talentenkaarten.
            </p>

            <div className="animation-container" ref={ref}>
              {isDeckInView ? <Deck /> : null}
            </div>

            <p>
              Mensen met autisme hebben door hun manier van informatie verwerken
              een eigen manier van denken, ontwikkelen en groeien. Ze beschikken
              daardoor vaak over eigen talenten. Talenten die zich anders
              openbaren, maar ook talenten die juist versterkt worden door
              autisme. Het is belangrijk dat kinderen (en hun omgeving) zelf
              gaan zien dat er kracht zit in al deze eigenschappen of talenten.
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}
